<template>
  <div class="b-card-content">
    <b-form>
      <div class="form-pool-choice px-5 pt-3 pb-4">
        <b-row align-v="center" align-h="between" no-gutters>
          <b-col>
            <div class="caption">
              Pool tokens to handover
              <span v-b-tooltip.hover title="Number of pool tokens you will give" clickable>
                                <b-icon-question-circle></b-icon-question-circle>
                            </span>
            </div>
            <b-form-input
                placeholder="Enter amount"
                v-model="form.poolTokensHandOver"
                v-on:input="checkIfValid">
            </b-form-input>

          </b-col>
        </b-row>
      </div>
      <b-row class="px-5 pt-3">
        <b-col sm="4">
          <TextBlock label="Pool tokens available"
                     tooltip="Number of pool tokens you own, not including those deposited in Governance"
                     :amount="form.poolTokensAvailable"
                     :copy="form.poolTokensAvailableTooltip"
                     :amountTooltip="form.poolTokensAvailableTooltip"
          ></TextBlock>
        </b-col>
        <b-col sm="4" class="mt-3 mt-sm-0">
          <TextBlock label="Output A"
                     :amount="form.outputA"
                     tooltip="Amount of tokens you will receive"
                     :amountTooltip="form.outputATooltip"
          ></TextBlock>
        </b-col>
        <b-col sm="4" class="mt-3 mt-sm-0">
          <TextBlock label="Output B"
                     :amount="form.outputB"
                     tooltip="Amount of tokens you will receive"
                     :amountTooltip="form.outputBTooltip"
          ></TextBlock>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import {SET_POOLS_TOKEN_DATA} from "@/store/modules/pools.module";
import {mathRound} from "@/helpers/utils";
import BigNumber from "bignumber.js";

export default {
  name: "Withdraw",
  data: function () {
    return {
      form: {
        poolTokensHandOver: '',
        poolTokensOwned: '',
        poolTokensAvailable: '',
        poolTokensAvailableTooltip: '',
        outputA: '',
        outputATooltip: '',
        outputB: '',
        outputBTooltip: '',
      },
      providedPool: null
    }
  },
  watch: {
    'form.poolTokensHandOver': {
      handler: async function (val) {
        await this.calculateWithdrawData(val)
      },
      immediate: true
    },
  },
  components: {
    TextBlock: () => import("@/components/form/TextBlock"),
  },
  methods: {
    ...mapMutations([SET_POOLS_TOKEN_DATA]),
    async calculateWithdrawData(val) {
      const pool = this.$store.state.pools
      const amountToHandover = +val || 0

      this.providedPool = this.getProvidedPool
      this.form.poolTokensAvailable = mathRound(
          +(+this.providedPool.pool_tokens_owned - +this.providedPool.pool_tokens_deposited) || 0).toString()
      this.form.poolTokensAvailableTooltip =
          new BigNumber(this.providedPool.pool_tokens_owned).minus(this.providedPool.pool_tokens_deposited).valueOf()
      this.form.poolTokensOwned = mathRound(+(+this.providedPool.pool_tokens_owned) || 0).toString()
      this.form.outputA = mathRound(amountToHandover * +pool.from.balance / +pool.total_pool_tokens).toString()
      this.form.outputB = mathRound(amountToHandover * +pool.to.balance / +pool.total_pool_tokens).toString()
      this.form.outputATooltip = new BigNumber(amountToHandover).multipliedBy(pool.from.balance).dividedBy(pool.total_pool_tokens).valueOf()
      this.form.outputBTooltip = new BigNumber(amountToHandover).multipliedBy(pool.to.balance).dividedBy(pool.total_pool_tokens).valueOf()

      this.setPoolsTokenData({dst: 'from', data: {amount: new BigNumber(this.form.outputA || 0)}})
      this.setPoolsTokenData({dst: 'to', data: {amount: new BigNumber(this.form.outputB || 0)}})
    },
    checkIfValid() {
      let currentValue = new BigNumber(this.form.poolTokensHandOver)
      if (currentValue.lte(this.form.poolTokensAvailableTooltip)
          && currentValue.gt(0)) {
        this.$emit('valid', true);
      } else {
        this.$emit('valid', false);
      }
    },
    resetForm() {
      this.form = {
        poolTokensHandOver: '',
        poolTokensOwned: '',
        poolTokensAvailable: '',
        poolTokensAvailableTooltip: '',
        outputA: '',
        outputATooltip: '',
        outputB: '',
        outputBTooltip: '',
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProvidedPool'
    ])
  }
}
</script>

<style scoped>

</style>
